import BaseService from '@/services/BaseService'

let resourceName = 'role'

class RoleService extends BaseService {
}

const roleService = new RoleService(resourceName)

export default roleService
