<template>
  <div class="user-index">
    <my-search-bar>
      <el-form :inline="true">
        <el-form-item v-show="isSuperadmin">
          <el-select
            v-model="searchModel.companyGuid"
            placeholder="所属公司"
            clearable>
            <el-option
              v-for="company in companys"
              :key="company.companyGuid"
              :label="company.companyName"
              :value="company.companyGuid">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-show="isSuperadmin">
          <el-select
            v-model="searchModel.projectGuid"
            placeholder="所属项目"
            clearable>
            <el-option
              v-for="project in projects"
              :key="project.projectGuid"
              :label="project.projectName"
              :value="project.projectGuid">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="searchModel.tenderGuid"
            placeholder="所属标段"
            clearable>
            <el-option
              v-for="tender in tenders"
              :key="tender.tenderGuid"
              :label="tender.tenderName"
              :value="tender.tenderGuid">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-input
            type="text"
            v-model="searchModel.staffName"
            placeholder="姓名"
            clearable>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleSearchClick">查询</el-button>
          <el-button type="primary" @click="handleAddClick">添加</el-button>
        </el-form-item>
      </el-form>
    </my-search-bar>

    <my-el-table
      :data="userList"
      :total="total"
      :page-index="pageIndex"
      :page-size="pageSize"
      @size-change="handlePageSizeChange"
      @current-change="handlePageIndexChange"
      >
      <el-table-column prop="index" width="50" header-align="center" align="center" label="序号"></el-table-column>
      <el-table-column prop="userName" width="120" header-align="center" align="center" label="名称"></el-table-column>
      <el-table-column prop="staffName" width="120" header-align="center" align="center" label="姓名"></el-table-column>
      <el-table-column prop="identityName" width="90" header-align="center" align="center" label="身份"></el-table-column>
      <el-table-column prop="roleName" width="160" header-align="center" align="center" label="角色"></el-table-column>
      <el-table-column prop="tenderName" width="60" header-align="center" align="center" label="标段"></el-table-column>
      <el-table-column prop="projectName" header-align="center" align="center" label="项目"></el-table-column>
      <el-table-column prop="companyName" header-align="center" align="center" label="公司"></el-table-column>
      <el-table-column prop="createUserStaffName" width="80" header-align="center" align="center" label="创建者"></el-table-column>
      <el-table-column prop="sortId" width="50" header-align="center" align="center" label="排序"></el-table-column>
      <el-table-column width="160" :formatter="timeFormatter" prop="createTime" header-align="center" align="center" label="创建时间"></el-table-column>
      <el-table-column width="90" header-align="center" align="center" label="操作">
        <template v-if="!scope.row.empty" slot-scope="scope">
          <el-tooltip v-if="couldSeePassword" class="item" effect="light" content="查看密码" :open-delay="500" placement="top">
            <i @click="handleSeePasswordClick(scope.row)" class="el-icon-unlock mr-2 text-success operation-button"></i>
          </el-tooltip>
          <el-tooltip class="item" effect="light" content="编辑" :open-delay="500" placement="top">
            <i @click="handleEditClick(scope.row)" class="text-info far fa-edit mr-2 operation-button"></i>
          </el-tooltip>
          <el-tooltip class="item" effect="light" content="删除" :open-delay="500" placement="top">
            <i @click="handleDeleteClick(scope.row)" class="text-danger far fa-trash-alt operation-button"></i>
          </el-tooltip>
        </template>
      </el-table-column>
    </my-el-table>

    <my-dialog ref="formDialog"
      :title="formDialogTitle"
      ok-text="保存"
      cancel-text="取消"
      @ok-click="handleOkClick">
      <user-form
        ref="userForm"
        :userModel="userModel">
      </user-form>
    </my-dialog>

    <my-dialog ref="passwordDialog"
      title="查看密码"
      cancel-text="关闭"
      :hide-ok="true"
      >
      <h5 style="text-align: center">{{ password }}</h5>
    </my-dialog>
  </div>
</template>

<script>
import MySearchBar from '@/components/MySearchBar/MySearchBar'
import MyDialog from '@/components/MyDialog/MyDialog'
import MyElTable from '@/components/MyElTable/MyElTable'
import UserForm from '@/views/UserManage/UserForm'
import UserModel from '@/model/UserModel'
import userService from '@/services/userService'
import PageOperation from '@/mixins/PageOperation'
import PageInfo from '@/support/PageInfo'
import companyService from '@/services/companyService'
import projectService from '@/services/projectService'
import tenderService from '@/services/tenderService'
import config from '@/config'
import auth from '@/common/auth'
import userTenderService from '@/services/userTenderService'

export default {
  name: 'UserIndex',
  mixins: [
    PageOperation
  ],
  components: {
    MyDialog,
    MySearchBar,
    MyElTable,
    UserForm
  },
  data () {
    return {
      password: '',
      total: 0,
      userList: [],
      userModel: new UserModel(),
      formDialogTitle: '',
      searchModel: {
        staffName: '',
        companyGuid: '',
        projectGuid: '',
        tenderGuid: ''
      },
      companys: [],
      projects: [],
      tenders: []
    }
  },
  watch: {
    companyGuid (newVal) {
      this.$set(this.searchModel, 'projectGuid', '')
      this.$set(this.searchModel, 'tenderGuid', '')
      this.projects = []
      this.tenders = []
      newVal && this._getProjects(newVal)
    },
    projectGuid (newVal) {
      this.$set(this.searchModel, 'tenderGuid', '')
      this.tenders = []
      newVal && this._getTenders(newVal)
    }
  },
  computed: {
    isSuperadmin () {
      return config.superIdenties.indexOf(auth.getUserInfo().identityGuid) > -1
    },
    couldSeePassword () {
      return config.proIdentities.indexOf(auth.getUserInfo().identityGuid) > -1 || this.isSuperadmin
    },
    companyGuid () {
      return this.searchModel.companyGuid
    },
    projectGuid () {
      return this.searchModel.projectGuid
    }
  },
  methods: {
    handleSeePasswordClick (row) {
      this.password = row.password
      this.$refs.passwordDialog.open()
    },
    handleSearchClick () {
      this._getUserList()
    },
    handleAddClick () {
      this.formDialogTitle = '添加用户信息'
      this.userModel = new UserModel()
      this.$refs.formDialog.open()
    },
    handleEditClick (row) {
      this.formDialogTitle = '编辑用户信息'
      this.userModel = new UserModel(row)
      this.$refs.formDialog.open()
    },
    handleDeleteClick (row) {
      this.$confirm('您确定要删除吗？')
        .then(() => {
          let _userModel = new UserModel(row)
          userService.delete(_userModel.resourceId)
            .then(res => {
              window.console.log(res)
              if (res.data.code === 1) {
                this.$message({
                  type: 'success',
                  message: '删除成功！'
                })
                this._getUserList()
              } else {
                this.$message({
                  type: 'error',
                  message: res.data.msg
                })
              }
            })
            .catch(err => {
              window.console.log(err)
              this.$message({
                type: 'error',
                message: '删除失败！'
              })
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '您已取消删除！'
          })
        })
    },
    handleOkClick () {
      this.$refs.userForm.validate()(valid => {
        if (valid) {
          if (!this.userModel.resourceId) {
            this._save()
          } else {
            this._edit()
          }
        } else {
          this.$message({
            type: 'error',
            message: '请检查您的输入！'
          })
        }
      })
    },
    _getUserList () {
      if (!this.isSuperadmin) {
        this.searchModel.companyGuid = auth.getUserInfo().companyGuid
        this.searchModel.projectGuid = auth.getUserInfo().projectGuid
      }
      userService.list(new PageInfo({ pageIndex: this.pageIndex, pageSize: this.pageSize, fieldOrder: 'users.sortId' }), this.searchModel)
        .then(res => {
          if (res.data.code === 1) {
            this.total = res.data.pageCount
            if (this.total !== 0 && this.pageIndex > Math.ceil(this.total / this.pageSize)) {
              this.pageIndex = 1
              this._getUserList()
            }
            this.userList = res.data.data
            this.$message({
              type: 'success',
              message: '列表加载成功！'
            })
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          window.console.log(err)
          this.$message({
            type: 'error',
            message: '列表加载失败！'
          })
        })
    },
    _save () {
      userService.add(this.userModel)
        .then(res => {
          if (res.data.code === 1) {
            this.$refs.formDialog.close()
            this._getUserList()
            this.$message({
              type: 'success',
              message: '添加成功！'
            })
          } else {
            this.userModel.resourceId = ''
            this.userModel.userGuid = ''
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          this.userModel.resourceId = ''
          this.userModel.userGuid = ''
          this.$message({
            type: 'error',
            message: '添加失败！'
          })
          window.console.log(err.request.response)
        })
    },
    _edit () {
      userService.edit(this.userModel)
        .then(res => {
          window.console.log(res)
          if (res.data.code === 1) {
            this.$refs.formDialog.close()
            this._getUserList()
            this.$message({
              type: 'success',
              message: '修改成功！'
            })
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          this.$message({
            type: 'error',
            message: '修改失败！'
          })
          window.console.log(err.request.response)
        })
    },
    _getCompanys () {
      companyService.list()
        .then(res => {
          if (res.data.code === 1) {
            this.companys = res.data.data
          } else {
            this.$message({
              type: 'error',
              message: '获取数据失败！'
            })
          }
        })
        .catch(err => {
          this.$message({
            type: 'error',
            message: '获取数据失败！'
          })
          console.log(err.request.response)
        })
    },
    _getTenders (projectGuid) {
      tenderService.list({}, { projectGuid })
        .then(res => {
          if (res.data.code === 1) {
            this.tenders = res.data.data
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          this.$message({
            type: 'error',
            message: '获取数据失败！'
          })
          console.log(err.request.response)
        })
    },
    _getProjects (companyGuid) {
      projectService.list(new PageInfo({ pageIndex: 1, pageSize: config.bigPageSize, fieldOrder: 'projects.sortId' }), { companyGuid })
        .then(res => {
          if (res.data.code === 1) {
            this.projects = res.data.data
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          this.$message({
            type: 'error',
            message: '获取数据失败！'
          })
          console.log(err.request.response)
        })
    },
    async getUserTender () {
      const response = await userTenderService.listByUser(auth.getUserInfo().userGuid)
      this.userTenders = response.data.data
    },
    initialize () {
      if (!this.isSuperadmin) {
        const userInfo = auth.getUserInfo()
        this.$set(this.searchModel, 'companyGuid', userInfo.companyGuid)
        this.$set(this.searchModel, 'projectGuid', userInfo.projectGuid)
        this._getTenders(userInfo.projectGuid)
      }
    }
  },
  created () {
    this.initialize()
    this._getUserList()
    this.getUserTender()
    this.refreshMethod = this._getUserList

    this._getCompanys()
  }
}
</script>
