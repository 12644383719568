<template>
  <el-form
    ref="elForm"
    :rules="rules"
    :model="userModel"
    label-width="110px"
    >
    <el-form-item label="名称：" prop="userName">
      <el-input
        type="text"
        clearable
        v-model="userModel.userName" />
    </el-form-item>
    <el-form-item label="姓名：" prop="staffName">
      <el-input
        type="text"
        v-model="userModel.staffName" />
    </el-form-item>
    <el-form-item label="联系方式：" prop="mobile">
      <el-input
        type="text"
        v-model="userModel.mobile">
        <template slot="append">
          <i class="el-icon-refresh" :class="{ waiting: isWaiting }" style="cursor: pointer;" @click="wechatSync"></i>
        </template>
      </el-input>
    </el-form-item>
    <el-form-item label="微信OpenID：" prop="openID">
      <el-input
        type="text"
        v-model="userModel.openID" />
    </el-form-item>
    <el-form-item label="微信组号：" prop="groupID">
      <el-input
        type="text"
        v-model="userModel.groupID" />
    </el-form-item>
    <el-form-item label="免打扰：" prop="noDisturbing">
      <el-switch
        v-model.number="userModel.noDisturbing"
        active-color="#13ce66"
        inactive-color="#ff4949"
        :active-value="0"
        :inactive-value="1"
        >
      </el-switch>
    </el-form-item>
    <el-form-item label="所属公司：" prop="companyGuid" v-show="isSuperadmin">
      <el-select v-model="userModel.companyGuid" clearable>
        <el-option
          v-for="company in companys"
          :key="company.companyGuid"
          :label="company.companyName"
          :value="company.companyGuid"
          >
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="所属项目：" prop="projectGuid" v-show="isSuperadmin">
      <el-select v-model="userModel.projectGuid" clearable>
        <el-option
          v-for="project in projects"
          :key="project.projectGuid"
          :label="project.projectName"
          :value="project.projectGuid">
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="所属标段：" prop="tenderGuid">
      <el-select v-model="userModel.tenderGuid" clearable>
        <el-option
          v-for="tender in tenders"
          :key="tender.tenderGuid"
          :label="tender.tenderName"
          :value="tender.tenderGuid">
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="身份：" prop="identityGuid">
      <el-select v-model="userModel.identityGuid" clearable>
        <el-option
          v-for="identity in identities"
          :key="identity.identityGuid"
          :label="identity.identityName"
          :value="identity.identityGuid">
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="角色：" prop="roleGuidList">
      <el-select v-model="userModel.roleGuidList" multiple clearable>
        <el-option
          v-for="role in roles"
          :key="role.roleGuid"
          :label="role.roleName"
          :value="role.roleGuid">
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="管理标段：" prop="tenderGuidList">
      <el-select v-model="userModel.tenderGuidList" multiple clearable>
        <el-option
          v-for="tender in tenders"
          :key="tender.tenderGuid"
          :label="tender.tenderName"
          :value="tender.tenderGuid">
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="账号到期日期">
      <el-date-picker
        v-model="userModel.endDate"
        type="date"
        value-format="yyyy-MM-dd"
        format="yyyy-MM-dd"
        style="width: 100%;"
        >
      </el-date-picker>
    </el-form-item>
    <el-form-item label="排序：" prop="sortId">
      <el-input-number
        v-model="userModel.sortId"
        :step="1"
        :min="0"
        :precision="0"
        step-strictly>
      </el-input-number>
    </el-form-item>
  </el-form>
</template>

<script>
import UserModel from '@/model/UserModel'
import projectService from '@/services/projectService'
import tenderService from '@/services/tenderService'
import userRoleService from '@/services/userRoleService'
import userTenderService from '@/services/userTenderService'
import identityService from '@/services/identityService'
import companyService from '@/services/companyService'
import roleService from '@/services/roleService'
import PageInfo from '@/support/PageInfo'
import config from '@/config'
import axios from 'axios'
import auth from '@/common/auth'

export default {
  name: 'UserForm',
  components: {
  },
  props: {
    userModel: {
      type: UserModel,
      default () {
        return new UserModel()
      }
    }
  },
  data () {
    return {
      isWaiting: false,
      rules: {
        userName: [
          {
            required: true,
            message: '请输入名称',
            trigger: 'blur'
          }
        ],
        staffName: [
          {
            required: true,
            message: '请输入姓名',
            trigger: 'blur'
          }
        ],
        companyGuid: [
          {
            required: true,
            message: '请选择所属公司',
            trigger: 'blur'
          }
        ],
        identityGuid: [
          {
            required: true,
            message: '请选择身份',
            trigger: 'blur'
          }
        ],
        sortId: [
          {
            required: true,
            message: '请输入排序',
            trigger: 'blur'
          }
        ]
      },
      projects: [],
      tenders: [],
      identities: [],
      companys: [],
      roles: [],
      roleProps: {
        label: 'roleName',
        children: 'children'
      },
      tenderProps: {
        label: 'tenderName',
        children: 'children'
      }
    }
  },
  computed: {
    isSuperadmin () {
      return config.superIdenties.indexOf(auth.getUserInfo().identityGuid) > -1
    },
    companyGuid () {
      return this.userModel.companyGuid
    },
    projectGuid () {
      return this.userModel.projectGuid
    },
    noDisturbing () {
      return this.userModel.noDisturbing
    }
  },
  watch: {
    noDisturbing (newVal) {
      if (this.userModel.mobile) {
        this.$myLoading()
        axios.get('http://wechat.ljkjkf.com/onOff', {
          params: {
            telNumber: this.userModel.mobile,
            num: newVal
          }
        })
          .then(res => {
            console.log(res)
            this.$loading().close()
            this.$message({
              type: 'success',
              message: '设置免打扰成功！'
            })
          })
          .catch(err => {
            console.log(err)
            this.$loading().close()
          })
      }
    },
    companyGuid (newVal) {
      this.userModel.projectGuid = ''
      this.userModel.tenderGuid = ''
      this.projects = []
      this.tenders = []
      newVal && this._getProjects(newVal)
    },
    projectGuid (newVal) {
      this.userModel.tenderGuid = ''
      this.tenders = []
      newVal && this._getTenders(newVal)
    }
  },
  methods: {
    validate () {
      return this.$refs.elForm.validate
    },
    wechatSync () {
      this.isWaiting = true
      if (!this.userModel.mobile) {
        this.userModel.openID = ''
        this.userModel.groupID = ''
        this.isWaiting = false
        return
      }
      axios.get('http://wechat.ljkjkf.com/selectByTel', {
        params: {
          telNumber: this.userModel.mobile
        }
      })
        .then(res => {
          console.log(res)
          if (res.data.code === '200') {
            this.userModel.openID = res.data.obj.openid
            this.userModel.groupID = res.data.obj.groupid
          }
          this.isWaiting = false
        })
        .catch(err => {
          console.log(err)
          this.isWaiting = false
        })
    },
    _getRoles () {
      roleService.list()
        .then(res => {
          if (res.data.code === 1) {
            this.roles = res.data.data
            if (!this.roles.length) {
              this.userModel.roleGuidList = []
            } else {
              userRoleService.listByUser(this.userModel.userGuid)
                .then(res => {
                  if (res.data.code === 1) {
                    this.userModel.roleGuidList = res.data.data.map(item => item.roleGuid)
                  } else {
                    this.$message({
                      type: 'error',
                      message: res.data.msg
                    })
                  }
                })
                .catch(err => {
                  this.$message({
                    type: 'error',
                    message: '获取数据失败！'
                  })
                  console.log(err.request.response)
                })
            }
          } else {
            this.$message({
              type: 'error',
              message: '获取数据失败！'
            })
          }
        })
        .catch(err => {
          this.$message({
            type: 'error',
            message: '获取数据失败！'
          })
          console.log(err.request.response)
        })
    },
    async _getCompanys () {
      try {
        const res = await companyService.list()
        if (res.data.code === 1) {
          this.companys = res.data.data
        } else {
          this.$message({
            type: 'error',
            message: '获取数据失败！'
          })
        }
      } catch (err) {
        this.$message.error('获取数据失败')
        console.dir(err)
      }
    },
    _getIdentities () {
      identityService.list()
        .then(res => {
          if (res.data.code === 1) {
            this.identities = res.data.data
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          this.$message({
            type: 'error',
            message: '获取数据失败！'
          })
          console.log(err.request.response)
        })
    },
    _getTenders (projectGuid) {
      tenderService.list({}, { projectGuid })
        .then(res => {
          if (res.data.code === 1) {
            this.tenders = res.data.data
            if (!this.tenders.length) {
              this.userModel.tenderGuid = ''
              this.userModel.tenderGuidList = []
            } else {
              userTenderService.listByUser(this.userModel.userGuid)
                .then(res => {
                  if (res.data.code === 1) {
                    this.userModel.tenderGuidList = res.data.data.map(item => item.tenderGuid)
                  } else {
                    this.$message({
                      type: 'error',
                      message: res.data.msg
                    })
                  }
                })
                .catch(err => {
                  this.$message({
                    type: 'error',
                    message: '获取数据失败！'
                  })
                  console.log(err.request.response)
                })
            }
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          this.$message({
            type: 'error',
            message: '获取数据失败！'
          })
          console.log(err.request.response)
        })
    },
    async _getProjects (companyGuid) {
      try {
        const res = await projectService.list(new PageInfo({ pageIndex: 1, pageSize: config.bigPageSize, fieldOrder: 'projects.sortId' }), { companyGuid })
        if (res.data.code === 1) {
          this.projects = res.data.data
          if (!this.projects.length) {
            this.userModel.projectGuid = ''
            this.userModel.tenderGuid = ''
          }
        } else {
          this.$message({
            type: 'error',
            message: res.data.msg
          })
        }
      } catch (err) {
        this.$message({
          type: 'error',
          message: '获取数据失败！'
        })
        console.log(err.request.response)
      }
    }
  },
  async created () {
    const userInfo = auth.getUserInfo()
    if (!this.isSuperadmin) {
      this.userModel.companyGuid = this.userModel.companyGuid || userInfo.companyGuid
      this.userModel.projectGuid = this.userModel.projectGuid || userInfo.projectGuid
    }
    this._getIdentities()
    this._getCompanys()
    this._getRoles()
    if (this.userModel.companyGuid) {
      await this._getProjects(this.userModel.companyGuid)
      if (!this.isSuperadmin) {
        this.userModel.projectGuid = userInfo.projectGuid
      }
    }
    if (this.userModel.projectGuid) {
      await this._getTenders(this.userModel.projectGuid)
    }
    this.userModel.projectGuid && this._getTenders(this.userModel.projectGuid)
  }
}
</script>

<style scoped lang="scss">
.waiting {
  animation: turning 1s linear infinite !important;
}
@keyframes turning {
  0% {
    transform: rotate3d(0, 0, 1, 0);
  }
  50% {
    transform: rotate3d(0, 0, 1, 180deg);
  }
  100% {
    transform: rotate3d(0, 0, 1, 360deg);
  }
}
</style>
