import utility from '@/common/utility'
import auth from '@/common/auth'

export default class UserModel {
  constructor (props) {
    if (props) {
      this.resourceId = props.userGuid
      this.userGuid = props.userGuid
      this.userName = props.userName
      this.staffName = props.staffName
      this.password = props.password
      this.identityGuid = props.identityGuid
      this.identityName = props.identityName
      this.tenderGuid = props.tenderGuid
      this.tenderName = props.tenderName
      this.projectGuid = props.projectGuid
      this.projectName = props.projectName
      this.companyGuid = props.companyGuid
      this.companyName = props.companyName
      this.mobile = props.mobile
      this.createUserStaffName = props.createUserStaffName
      this.sortId = props.sortId
      this.roleGuidList = props.roleGuidList
      this.tenderGuidList = props.tenderGuidList
      this.createUserGuid = props.createUserGuid
      this.createTime = props.createTime
      this.openID = props.openID
      this.groupID = props.groupID
      this.noDisturbing = props.noDisturbing
      this.endDate = props.endDate
    }
    this.init()
  }

  init () {
    if (!this.resourceId) this.resourceId = ''
    if (!this.userGuid) this.userGuid = ''
    if (!this.userName) this.userName = ''
    if (!this.staffName) this.staffName = ''
    if (!this.password) this.password = ''
    if (!this.identityGuid) this.identityGuid = ''
    if (!this.identityName) this.identityName = ''
    if (!this.tenderGuid) this.tenderGuid = ''
    if (!this.tenderName) this.tenderName = ''
    if (!this.projectGuid) this.projectGuid = ''
    if (!this.projectName) this.projectName = ''
    if (!this.companyGuid) this.companyGuid = ''
    if (!this.companyName) this.companyName = ''
    if (!this.mobile) this.mobile = ''
    if (!this.createUserStaffName) this.createUserStaffName = ''
    if (!this.roleGuidList) this.roleGuidList = []
    if (!this.tenderGuidList) this.tenderGuidList = []
    if (!this.sortId) this.sortId = 0
    if (!this.createUserGuid) this.createUserGuid = auth.getUserInfo().userGuid
    if (!this.createTime) this.createTime = utility.getCurrentTime()
    if (!this.openID) this.openID = ''
    if (!this.groupID) this.groupID = ''
    if (!this.noDisturbing) this.noDisturbing = 0
    if (!this.endDate) this.endDate = ''
  }

  generatePrimaryKey () {
    this.userGuid = utility.getUuid()
    this.resourceId = this.userGuid
  }
}
